<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9.5"
      cy="8"
      r="2.5"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
    />
    <circle
      cx="14.5"
      cy="16"
      r="2.5"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.8551 9C12.9494 8.68315 13 8.34749 13 8C13 7.65251 12.9494 7.31685 12.8551 7H20.1C20.5971 7 21 7.44772 21 8C21 8.55228 20.5971 9 20.1 9H12.8551ZM6.14494 9H3.9C3.40294 9 3 8.55228 3 8C3 7.44772 3.40294 7 3.9 7H6.14494C6.05064 7.31685 6 7.65251 6 8C6 8.34749 6.05064 8.68315 6.14494 9Z"
      :fill="getBaseColor(darkMode)"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.8551 17C17.9494 16.6831 18 16.3475 18 16C18 15.6525 17.9494 15.3169 17.8551 15H20.1C20.5971 15 21 15.4477 21 16C21 16.5523 20.5971 17 20.1 17H17.8551ZM11.1449 17H3.9C3.40294 17 3 16.5523 3 16C3 15.4477 3.40294 15 3.9 15H11.1449C11.0506 15.3169 11 15.6525 11 16C11 16.3475 11.0506 16.6831 11.1449 17Z"
      :fill="getBaseColor(darkMode)"
    />
  </svg>
</template>
